<template>
<v-dialog v-model="dialog" width="650">
  <v-card >
    <v-container class="pa-4">
			<v-row class="pa-3 d-flex justify-space-between">
				<span class="text-subtitle-1">
					发分享（@Ta）
				</span>
				<a class="hide-modal-icon" @click="dialog = false">
          <v-icon right dark>mdi-close</v-icon>
        </a>
			</v-row>
      <v-row >
        <v-textarea ref="intercomDescription" :rows="4" placeholder="你在忙什么" background-color="white" filled auto-grow outlined v-model="ic_desc" @input="" @blur="onBlur" @focus="onFocus">
        </v-textarea>
      </v-row>
      <v-row class="d-flex justify-start ma-0 pa-0">

        <div class="d-flex pa-2 justify-center intercom-btn">
          <v-menu top origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <span class="ma-2">@ 同事</span>
              </span>
            </template>
            <v-card>
              <UserPicker @pick="pickUser" />
            </v-card>
          </v-menu>
        </div>

        <div class="d-flex pa-2 justify-center intercom-btn" @click="_handleAllMemberClick">
          <span>
            <span>@ 所有同事</span>
          </span>
        </div>

        <div class=" d-flex justify-center intercom-btn">
          <EmojiPicker @inputEmoji="_handleInputEmojiClick" />
        </div>

        <div class="d-flex pl-3 justify-center">
          <v-btn color="primary" :disabled="
                        DescriptionCheck.length > 0 || intercomFiles.length > 0
                            ? false
                            : true
                    " @click="_handleICSubmitClick">{{'发布'}}</v-btn>
        </div>
      </v-row>

    </v-container>
  </v-card>
</v-dialog>
</template>



<script>
import EmojiPicker from "@/components/common/EmojiPicker.vue";
import UserPicker from "@/components/common/UserPicker.vue";
import {
  mapGetters,
  mapActions
} from "vuex";
export default {
  props: ["ic_obj",],
  components: {
    UserPicker,
    EmojiPicker,
  },
  data() {
    return {
      ic_desc: "@[所有同事:all_members] 目标：Test（2021/09/20-2021/09/26）E周报",
			ic_desc_default:"",
      ic_reply_ic: null,
      ic_sms: false,
      triple_down: true,
      chkFocusBlur: false,
			dialog: false,
    };
  },
  computed: {
    ...mapGetters("intercom", ["intercom"]),
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("fileUpload", ["intercomFiles"]),
    DescriptionCheck() {
      return this.ic_desc.replaceAll(/@\[.*\]/g, '');
    }
  },
  watch: {
    ic_obj(value) {
      
      this.getIntercom(value).then((res) => {
        
      });
    },
    intercom(value, oleValue) {
      
    }
  },
  created() {
    this.getIntercom(this.ic_obj);
  },
  methods: {
		...mapActions('app', ['setNotification']),
    ...mapActions("intercom", [
      "getIntercom",
      "addIntercom",
      "deleteIntercom",
      "updateIntercom",
    ]),
    ...mapActions("fileUpload", [
      "initFiles",
      "fileUpload",
      "deleteFileUploadById",
    ]),
    onBlur(e) {
      this.chkFocusBlur = false;
    },
    onFocus(e) {
      this.chkFocusBlur = true;
    },
    displayIntercomListMore() {
      this.triple_down = !this.triple_down;
      if (this.intercom.length < 4) {
        this.triple_down = true;
      }
    },
    _handleFileInputClick() {
      this.$refs.fileIntercom.click();
    },

    pickUser(param) {
      this.ic_desc += "@[" + param.user.user_post_address + "]";
      
    },

    _handleAllMemberClick() {
      this.ic_desc += "@[所有同事:all_members]";
    },

    _handleInputEmojiClick(e) {
      this.ic_desc += e;
      this.$refs.intercomDescription.focus();
    },

    setFileForIntercom(e) {
      this.file = e.target.files[0];
      var formData = new FormData();
      formData.append("files", this.file);
      this.fileUpload({
        type: "intercom",
        value: formData
      });
    },
    removeFile(id) {
      this.deleteFileUploadById({
        type: "intercom",
        file_id: id
      });
    },

    _handleReplyBtnClick(prop) {
      this.ic_reply_ic = prop;
      this.$refs.intercomDescription.focus();
      
    },
    _cancelReply() {
      this.ic_reply_ic = null;
    },
    handle_deleteCertainIntercom(prop) {
      this.deleteIntercom({
        ic_id: prop,
      });
    },
    _handleICSubmitClick() {
      let files = [];
      this.intercomFiles.forEach((f) => {
        files.push(f.file_id);
      });
      this.initFiles({
        type: "intercom"
      });

      let ic_add = {
        ...this.ic_obj
      };
      ic_add.ic_description = this.ic_desc;
      ic_add.ic_reply_ic = !!this.ic_reply_ic ?
        this.ic_reply_ic.ic_id :
        null;
      ic_add.ic_thumbup = null;
      ic_add.ic_uploaded_file = files.toString();
			
			this.setNotification({
          status: 'success',
          text: '分享成功!'
        })
      //  console.log(ic_add,'ic_addddddd')
       this.addIntercom(ic_add);
			// this.$emit("submit",ic_add);
      this.ic_desc = this.ic_desc_default;
      this.ic_reply_ic = null;
			this.dialog = false
    },
		open(param) {
			this.ic_desc_default = "@[所有同事:all_members] 目标："+ param.ob_name+"（"+ param.e_start+"-" + param.e_end+"）E周报 "  +location.host + param.path+  " ,点击链接即可查看～";
			this.ic_desc = this.ic_desc_default;
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onOk() {
      this.dialog = false
    },
  },
};
</script>


<style scoped>
.hide-modal-icon i {
  font-size: 17px;
  font-weight: bold;
  transition: color 0.3s ease-in 0s;
  color: rgb(20, 28, 40);
}

.hide-modal-icon i:hover {
  color: rgb(247, 105, 100);
}

.btn_2-icons {
  font-size: 17px !important;
  margin-right: 5px;
}

.intercom-btn {
  background-color: white;
  cursor: pointer;
  padding: 1px 1px;
  height: 35px;
  width: 90px;
  font-size: 13px;
  color: rgb(86, 94, 102);
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
}

.intercom-btn:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 2px;
}

.file_remove_close:hover {
  color: red !important;
}

.file_remove {
  transition: all ease 0.5s;
  opacity: 0;
}

.file-item:hover .file_remove {
  opacity: 1;
}
</style><style>
.sms-check label {
  color: rgb(86, 94, 102) !important;
  font-size: 13px !important;
  padding: 1px 1px;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
}

.sms-check .v-icon {
  font-size: 15px !important;
  line-height: 20px;
}

.progress_bottom_down_btn {
  font-size: 13px !important;
  color: rgb(40, 121, 255);
}

.progress_bottom_down:hover .progress_bottom_down_btn {
  color: blue !important;
}

.progress_bottom_down_btn:hover {
  color: red !important;
}
</style>
